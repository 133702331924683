import CardDescription from '../../../components/CardDescription'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import React from 'react'
import { getCurrentLanguage } from '../../../helper'
import { useTranslation } from 'react-i18next'

const Map = () => {
  const { t } = useTranslation()

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} className="flex">
          <CardDescription title={t('home_map_title')} description={t('home_map_description')} />
        </Grid>
        <Grid item xs={12} md={6}>
          <iframe
            style={{ width: '100%' }}
            title="Plan - Manoir de l'as de trèfle"
            height="436"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            id="gmap_canvas"
            src={`https://maps.google.com/maps?hl=${getCurrentLanguage()}&q=Manoir%20de%20l'as%20de%20tr%C3%A8fle%20+()&t=&z=12&ie=UTF8&iwloc=B&output=embed`}
          ></iframe>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Map
