import Home from '../pages/Home'
import Overview from '../pages/Overview'
import Rooms from '../pages/Rooms'

const routes = [
  {
    path: '/',
    handler: <Home />,
    seo: {
      canonical: 'https://manoirasdetrefle.fr/',
      description: 'seo_home_description',
      title: 'seo_home_title',
    },
  },
  {
    path: '/overview',
    handler: <Overview />,
    seo: {
      canonical: 'https://manoirasdetrefle.fr/overview',
      description: 'seo_overview_description',
      title: 'seo_overview_title',
    },
  },
  {
    path: '/chambre-d-hotes',
    handler: <Rooms roomIndex="0" />,
    seo: {
      canonical: 'https://manoirasdetrefle.fr/chambre-d-hotes',
      description: 'seo_rooms_0_description',
      title: 'seo_rooms_0_title',
    },
  },
  {
    path: '/petite-maison',
    handler: <Rooms roomIndex="1" />,
    seo: {
      canonical: 'https://manoirasdetrefle.fr/petite-maison',
      description: 'seo_rooms_1_description',
      title: 'seo_rooms_1_title',
    },
  },
  {
    path: '/colombages',
    handler: <Rooms roomIndex="2" />,
    seo: {
      canonical: 'https://manoirasdetrefle.fr/colombages',
      description: 'seo_rooms_2_description',
      title: 'seo_rooms_2_title',
    },
  },
  {
    path: '/gite-des-petits-cousins',
    handler: <Rooms roomIndex="3" />,
    seo: {
      canonical: 'https://manoirasdetrefle.fr/gite-des-petits-cousins',
      description: 'seo_rooms_3_description',
      title: 'seo_rooms_3_title',
    },
  },
]

export default routes
