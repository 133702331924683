import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { styled } from '@mui/material/styles'
import Divider from '@mui/material/Divider'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

import Map from './components/Map'

import background from '../../assets/manoir-as-de-trefle3.jpg'
import rooms from '../../assets/rooms.jpg'
import CardDescription from '../../components/CardDescription'
import Landscape from '../../components/Landscape'

const Root = styled('div')(({ theme }) => ({
  '.parallax': {
    backgroundImage: `url("${background}")`,
    backgroundPositionY: '64px',
  },
  '.image': {
    aspectRatio: '1/1',
  },
}))

const Home = () => {
  const { t } = useTranslation()

  return (
    <Root>
      <div className="parallax">
        <div className="shadow whitespace-break-spaces">{t('home_title')}</div>
      </div>
      <Container maxWidth="lg" sx={{ my: 10 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div className="flex overflow-hidden items-center image">
              <img src={rooms} alt={t('home_overview_rooms')} sx={{ width: '100%' }} />
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="flex">
            <CardDescription
              title={t('home_overview_title')}
              description={t('home_overview_description')}
              to="/overview"
              action={t('home_overview_button')}
            />
          </Grid>
        </Grid>
      </Container>
      <Divider sx={{ my: 10 }} />
      <Map />
      <Landscape />
    </Root>
  )
}

export default Home
