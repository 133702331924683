import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'

import background from '../../assets/overview.jpg'
import CardDescription from '../../components/CardDescription'
import { Rooms } from '../Rooms/helper'

const Root = styled('div')(({ theme }) => ({
  '.parallax': {
    backgroundImage: `url("${background}")`,
    height: '40vh',
    backgroundPosition: '50% -500px',

    '@media only screen and (max-device-width: 900px)': {
      '&': {
        backgroundPosition: 'center',
      },
    },
  },
  '.room': {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,.3)',
      zIndex: 2,
      transition: 'all .8s ease-in-out',
    },
    '&:hover:before': {
      backgroundColor: 'rgba(0,0,0,.6)',
    },
    '&:hover img': {
      transform: 'scale(1.05,1.05)',
    },
    '& img': {
      width: '100%',
      height: 'auto',
      float: 'left',
      transition: 'all .8s ease-in-out',
      transform: 'scale(1,1)',
    },
    '.caption': {
      position: 'absolute',
      top: '50%',
      left: 0,
      textAlign: 'center',
      padding: '0 20px',
      zIndex: 10,
      transform: 'translateY(-50%)',
      color: '#fff',
      width: '100%',
    },
  },
}))

const Overview = () => {
  const { t } = useTranslation()

  return (
    <Root>
      <div className="parallax">
        <div className="shadow">
          <div className="banner">
            <Typography variant="overline" component="h1" className="title uppercase">
              {t('overview_title')}
            </Typography>
            <Typography variant="h3" className="subtitle">
              {t('overview_subtitle')}
            </Typography>
          </div>
        </div>
      </div>
      <Container maxWidth="lg" sx={{ my: 10 }}>
        <CardDescription
          title={t('home_overview_title')}
          description={t('home_overview_description')}
          action={t('layout_header_book_now')}
        />
      </Container>
      <Grid container spacing={2} sx={{ mt: 10, p: 2 }}>
        {Rooms.map(({ title, link, featuredImg, group }, index) => (
          <Grid key={`room-${index}`} item xs={12} sm={6}>
            <Link component={RouterLink} to={link} className="room flex overflow-hidden">
              <img src={featuredImg} alt={`${t(title)} Manoir de l'As de Trèfle`} />
              <span className="caption">
                {[...Array(group).keys()].map((_, index) => (
                  <PeopleOutlineIcon key={`group-${index}`} sx={{ fontSize: 60, mb: 4 }} />
                ))}
                <Typography variant="h4">{t(title)}</Typography>
              </span>
            </Link>
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ my: 10 }} />
    </Root>
  )
}

export default Overview
