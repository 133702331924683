import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { grey } from '@mui/material/colors'

const Root = styled(Box)(({ theme }) => ({
  '.img': {
    position: 'relative',
    cursor: 'pointer',

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,.3)',
      zIndex: 2,
      transition: 'all .8s ease-in-out',
    },
    '&:hover:before': {
      backgroundColor: 'rgba(0,0,0,.6)',
    },
    '&:hover img': {
      transform: 'scale(1.05,1.05)',
    },
    '& img': {
      width: '100%',
      height: 'auto',
      aspectRatio: '1/1',
      objectFit: 'cover',
      transition: 'all .8s ease-in-out',
      transform: 'scale(1,1)',
    },
  },
}))

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  display: 'flex',
  justifyContent: 'center',
  outline: 'none',
  background: '#000',
  boxShadow: 24,

  '@media only screen and (max-device-width: 900px)': {
    '&': {
      width: '100%',
      height: '100%',
    },
  },

  '.close': {
    position: 'absolute',
    top: 8,
    right: 8,
    color: '#fff',
  },

  '.prev': {
    position: 'absolute',
    top: '50%',
    left: 8,
    transform: 'translateY(-50%)',
    color: '#fff',
  },
  '.next': {
    position: 'absolute',
    top: '50%',
    right: 8,
    transform: 'translateY(-50%)',
    color: '#fff',
  },

  img: {
    objectFit: 'contain',
  },
}

const Photos = ({ images }) => {
  const { t } = useTranslation()

  const [open, setOpen] = React.useState(null)
  const handleClose = () => setOpen(null)

  const index = images.findIndex(image => image.imgPath === open)

  const openNext = () => {
    if (index < images.length - 1) {
      setOpen(images[index + 1].imgPath)
    } else {
      setOpen(images[0].imgPath)
    }
  }

  const openPrev = () => {
    if (index > 0) {
      setOpen(images[index - 1].imgPath)
    } else {
      setOpen(images[images.length - 1].imgPath)
    }
  }

  const handleKeyDown = e => {
    if (e.keyCode === 37) openPrev()
    if (e.keyCode === 39) openNext()
  }

  return (
    <Root sx={{ background: grey[50], py: 6, mb: 10 }}>
      <Typography variant="h4" component="h2" gutterBottom color="primary" align="center" sx={{ mb: 6 }}>
        {t('Photos')}
      </Typography>
      <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid item xs={12} md={6} lg={2} key={index}>
            <div className="img flex overflow-hidden" onClick={() => setOpen(image.imgPath)}>
              <img key={index} src={image.imgPath} alt={t(image.label)} />
            </div>
          </Grid>
        ))}
      </Grid>
      <Modal open={!!open} onClose={handleClose} onKeyDown={handleKeyDown}>
        <Box sx={modalStyle}>
          <IconButton onClick={handleClose} size="large" className="close">
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <IconButton onClick={openPrev} size="large" className="prev">
            <NavigateBeforeIcon fontSize="inherit" />
          </IconButton>
          <IconButton onClick={openNext} size="large" className="next">
            <NavigateNextIcon fontSize="inherit" />
          </IconButton>
          <img src={open} alt="modal" />
        </Box>
      </Modal>
    </Root>
  )
}

export default Photos
