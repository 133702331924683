import * as React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

function Copyright() {
  const { t } = useTranslation()

  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {`© ${new Date().getFullYear()} Manoir de l'As de Trèfle. ${t('layout_footer_all_rights_reserved')}`}
    </Typography>
  )
}

const Footer = () => {
  const { t } = useTranslation()

  return (
    <Box sx={{ bgcolor: 'background.paper', pb: 6 }} component="footer">
      <Typography variant="subtitle1" align="center" color="text.secondary" component="p">
        {t('layout_footer_description')}
      </Typography>
      <Copyright />
    </Box>
  )
}

export default Footer
