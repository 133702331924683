import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import background from '../assets/manoir-as-de-trefle3.jpg'

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  background: `url("${background}") center center no-repeat`,
  backgroundSize: 'cover',
  padding: '160px 0 150px',
  margin: theme.spacing(10, 0),

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,.3)',
    zIndex: 1,
  },
  '.wrapper': {
    width: '100%',
    maxWidth: '650px',
    margin: '0 auto',
    position: 'relative',
    zIndex: 2,
    textAlign: 'center',
  },
}))

const Landscape = () => {
  const { t } = useTranslation()

  return (
    <Root>
      <div className="wrapper">
        <Typography className="title uppercase" variant="overline" component="span" color="white" sx={{ mb: 2 }}>
          {t('landscape_title')}
        </Typography>
        <Typography className="subtitle" variant="h3" component="h2" color="white" sx={{ mb: 4 }}>
          {t('landscape_subtitle')}
        </Typography>
        <Button variant="contained" color="primary" href="tel:+33677682959">
          {t('layout_header_book_now')}
        </Button>
      </div>
    </Root>
  )
}

export default Landscape
