import ch0 from '../../assets/chambre-d-hotes/0.jpg'
import ch1 from '../../assets/chambre-d-hotes/1.jpg'
import ch2 from '../../assets/chambre-d-hotes/2.jpg'
import ch3 from '../../assets/chambre-d-hotes/3.jpg'
import ch4 from '../../assets/chambre-d-hotes/4.jpg'
import ch5 from '../../assets/chambre-d-hotes/5.jpg'
import chambreDHotes from '../../assets/chambre-d-hotes.jpg'
import co0 from '../../assets/colombages/0.jpg'
import co1 from '../../assets/colombages/1.jpg'
import co10 from '../../assets/colombages/10.jpg'
import co11 from '../../assets/colombages/11.jpg'
import co12 from '../../assets/colombages/12.jpg'
import co13 from '../../assets/colombages/13.jpg'
import co14 from '../../assets/colombages/14.jpg'
import co2 from '../../assets/colombages/2.jpg'
import co3 from '../../assets/colombages/3.jpg'
import co4 from '../../assets/colombages/4.jpg'
import co5 from '../../assets/colombages/5.jpg'
import co6 from '../../assets/colombages/6.jpg'
import co7 from '../../assets/colombages/7.jpg'
import co8 from '../../assets/colombages/8.jpg'
import co9 from '../../assets/colombages/9.jpg'
import colombages from '../../assets/colombages.jpg'
import giteDesPetitsCousins from '../../assets/gite-des-petits-cousins.jpg'
import gpc0 from '../../assets/gite-des-petits-cousins/0.jpg'
import gpc1 from '../../assets/gite-des-petits-cousins/1.jpg'
import gpc10 from '../../assets/gite-des-petits-cousins/10.jpg'
import gpc2 from '../../assets/gite-des-petits-cousins/2.jpg'
import gpc3 from '../../assets/gite-des-petits-cousins/3.jpg'
import gpc4 from '../../assets/gite-des-petits-cousins/4.jpg'
import gpc5 from '../../assets/gite-des-petits-cousins/5.jpg'
import gpc6 from '../../assets/gite-des-petits-cousins/6.jpg'
import gpc7 from '../../assets/gite-des-petits-cousins/7.jpg'
import gpc8 from '../../assets/gite-des-petits-cousins/8.jpg'
import gpc9 from '../../assets/gite-des-petits-cousins/9.jpg'
import petiteMaison from '../../assets/petite-maison.jpg'
import pm0 from '../../assets/petite-maison/0.jpg'
import pm1 from '../../assets/petite-maison/1.jpg'
import pm2 from '../../assets/petite-maison/2.jpg'
import pm5 from '../../assets/petite-maison/5.jpg'
import pm6 from '../../assets/petite-maison/6.jpg'

export const Rooms = [
  {
    title: 'room.0.title',
    link: '/chambre-d-hotes',
    featuredImg: chambreDHotes,
    featuredImgBg: chambreDHotes,
    group: 1,
    images: [
      { label: 'room.0.title', imgPath: ch0 },
      { label: 'room.0.title', imgPath: ch1 },
      { label: 'room.0.title', imgPath: ch2 },
      { label: 'room.0.title', imgPath: ch3 },
      { label: 'room.0.title', imgPath: ch4 },
      { label: 'room.0.title', imgPath: ch5 },
    ],
    description: 'room.0.description',
    price: 'room.0.price',
    priceDetail: 'room.0.price.detail',
    amenities: {
      summary: 'room.0.amenities.summary',
      details: 'room.0.amenities.details',
    },
  },
  {
    title: 'room.1.title',
    link: '/petite-maison',
    featuredImg: petiteMaison,
    featuredImgBg: petiteMaison,
    group: 1,
    images: [
      { label: 'room.1.title', imgPath: pm0 },
      { label: 'room.1.title', imgPath: pm1 },
      { label: 'room.1.title', imgPath: pm2 },
      // { label: 'room.1.title', imgPath: pm3 },
      // { label: 'room.1.title', imgPath: pm4 },
      { label: 'room.1.title', imgPath: pm5 },
      { label: 'room.1.title', imgPath: pm6 },
    ],
    description: 'room.1.description',
    price: 'room.1.price',
    priceDetail: 'room.1.price.detail',
    amenities: {
      summary: 'room.1.amenities.summary',
      details: 'room.1.amenities.details',
    },
  },
  {
    title: 'room.2.title',
    link: '/colombages',
    featuredImg: colombages,
    featuredImgBg: colombages,
    group: 1,
    images: [
      { label: 'room.2.title', imgPath: co0 },
      { label: 'room.2.title', imgPath: co1 },
      { label: 'room.2.title', imgPath: co2 },
      { label: 'room.2.title', imgPath: co3 },
      { label: 'room.2.title', imgPath: co4 },
      { label: 'room.2.title', imgPath: co5 },
      { label: 'room.2.title', imgPath: co6 },
      { label: 'room.2.title', imgPath: co7 },
      { label: 'room.2.title', imgPath: co8 },
      { label: 'room.2.title', imgPath: co9 },
      { label: 'room.2.title', imgPath: co10 },
      { label: 'room.2.title', imgPath: co11 },
      { label: 'room.2.title', imgPath: co12 },
      { label: 'room.2.title', imgPath: co13 },
      { label: 'room.2.title', imgPath: co14 },
    ],
    description: 'room.2.description',
    price: 'room.2.price',
    priceDetail: 'room.2.price.detail',
    amenities: {
      summary: 'room.2.amenities.summary',
      details: 'room.2.amenities.details',
    },
  },
  {
    title: 'room.3.title',
    link: '/gite-des-petits-cousins',
    featuredImg: giteDesPetitsCousins,
    featuredImgBg: giteDesPetitsCousins,
    group: 2,
    images: [
      { label: 'room.3.title', imgPath: gpc0 },
      { label: 'room.3.title', imgPath: gpc1 },
      { label: 'room.3.title', imgPath: gpc2 },
      { label: 'room.3.title', imgPath: gpc3 },
      { label: 'room.3.title', imgPath: gpc4 },
      { label: 'room.3.title', imgPath: gpc5 },
      { label: 'room.3.title', imgPath: gpc6 },
      { label: 'room.3.title', imgPath: gpc7 },
      { label: 'room.3.title', imgPath: gpc8 },
      { label: 'room.3.title', imgPath: gpc9 },
      { label: 'room.3.title', imgPath: gpc10 },
    ],
    description: 'room.3.description',
    price: 'room.3.price',
    priceDetail: 'room.3.price.detail',
    amenities: {
      summary: 'room.3.amenities.summary',
      details: 'room.3.amenities.details',
    },
  },
]
