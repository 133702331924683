import * as React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Link as RouterLink } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

const Root = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.primary.main,

  '.description': {
    textAlignLast: 'center',
    fontWeight: 300,
  },
  '.detail': {
    fontWeight: 300,
    marginRight: theme.spacing(-2),
    alignSelf: 'flex-end',
  },
}))

const CardDescriptionAction = ({ to, action }) => (
  <Button
    sx={{ mt: 4 }}
    variant="contained"
    color="primary"
    {...(to
      ? {
          component: RouterLink,
          to,
        }
      : {
          href: 'tel:+33677682959',
        })}
  >
    {action}
  </Button>
)

const CardDescription = ({ title, description, to, action, priceDetail }) => {
  const { t } = useTranslation()

  return (
    <Root className="flex flex-col items-center justify-center" sx={{ p: { xs: 4, md: 6 } }}>
      <Typography color="primary" variant="h4" component="h2" align="center" className="uppercase">
        {title}
      </Typography>
      <Typography
        variant="body1"
        align="justify"
        sx={{ mt: 4 }}
        className="description whitespace-break-spaces"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {action && <CardDescriptionAction to={to} action={t(action)} />}
      {priceDetail && (
        <Typography
          className="detail whitespace-break-spaces"
          variant="caption"
          align="right"
          sx={{ mt: { xs: 2, md: 4 }, mb: { xs: -2, md: -4 } }}
        >
          *{t(priceDetail)}
        </Typography>
      )}
    </Root>
  )
}

export default CardDescription
