import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import * as React from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { initReactI18next, useTranslation } from 'react-i18next'

import CssBaseline from '@mui/material/CssBaseline'
import { Helmet } from 'react-helmet'
import Layout from './components/Layout'
import RoutesList from './config/routes'
import ScrollToTop from './components/ScrollToTop'
import { getCurrentLanguage } from './helper'
import i18n from 'i18next'
import translationEN from './locales/en.json'
import translationFR from './locales/fr.json'

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: getCurrentLanguage(),
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false,
  },
})

const SeoWrapper = ({ children, seo }) => {
  const { t } = useTranslation()

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href={seo.canonical} />
        <meta name="description" content={t(seo.description)} />
        <title>{t(seo.title)}</title>
      </Helmet>
      {children}
    </div>
  )
}

function App() {
  const defaultTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 1024,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      primary: {
        main: '#b28e59',
        contrastText: '#fff',
      },
      secondary: {
        main: '#fff',
        contrastText: '#000',
      },
    },
  })

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Layout>
          <Routes>
            {RoutesList.map(({ path, handler, seo }) => (
              <Route key={path} exact path={path} element={<SeoWrapper seo={seo}>{handler}</SeoWrapper>} />
            ))}
            {/* <Route path="*" element={<Page404 />} /> */}
          </Routes>
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
