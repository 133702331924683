import * as React from 'react'

import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { grey } from '@mui/material/colors'
import { Divider, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const Root = styled(Container)(({ theme }) => ({
  '.icon': {
    fontSize: '7rem',
    width: '7rem',
    height: '7rem',
    color: theme.palette.primary.main,
  },
  '.summary': {
    whiteSpace: 'pre-wrap',
    lineHeight: 2.5,
    fontWeight: 300,
  },
  '.leftCol': {
    background: grey[50],
  },
  '.amenities': {
    fontWeight: 300,
  },
}))

const Amenities = ({ amenities }) => {
  const { t } = useTranslation()
  const { summary, details } = amenities

  return (
    <Root maxWidth="lg">
      <Grid container spacing={10}>
        <Grid item xs={12} md={4} className="flex">
          <div className="p-4 flex flex-col items-center justify-center w-full text-center leftCol">
            <svg className="icon" viewBox="0 0 512 512">
              <path
                fill="#b28e59"
                d="M496.484,256.516h-0.516v-24.564c0-10.354-6.724-19.16-16.032-22.299v-105.96c0-4.142-3.358-7.5-7.5-7.5H39.564 c-4.142,0-7.5,3.358-7.5,7.5v105.959c-9.308,3.139-16.032,11.946-16.032,22.299v24.564h-0.516C6.96,256.516,0,263.477,0,272.031 v64.129c0,8.556,6.96,15.517,15.516,15.517h0.516v56.629c0,4.142,3.358,7.5,7.5,7.5h16.032c3.441,0,6.441-2.343,7.276-5.681 l14.612-58.448h389.095l14.612,58.448c0.834,3.338,3.834,5.681,7.276,5.681h16.032c4.142,0,7.5-3.358,7.5-7.5v-56.629h0.516 c8.556,0,15.516-6.961,15.516-15.517v-64.129C512,263.477,505.04,256.516,496.484,256.516z M33.708,400.807h-2.676v-49.129h14.959 L33.708,400.807z M480.969,400.807L480.969,400.807h-2.677l-12.282-49.129h14.959V400.807z M496.484,336.677H15.516 c-0.285,0-0.516-0.231-0.516-0.517v-64.129c0-0.285,0.231-0.516,0.516-0.516H440.37c4.142,0,7.5-3.358,7.5-7.5 c0-4.142-3.358-7.5-7.5-7.5H31.032v-24.564c0-4.705,3.828-8.532,8.532-8.532h16.033c4.142,0,7.5-3.358,7.5-7.5 c0-4.142-3.358-7.5-7.5-7.5h-8.533v-97.226h417.871v97.226h-41.113v-40.597c0-12.976-10.557-23.532-23.532-23.532H288.064 c-12.976,0-23.532,10.557-23.532,23.532v40.597h-17.064v-40.597c0-12.976-10.557-23.532-23.532-23.532H111.709 c-12.976,0-23.532,10.557-23.532,23.532v40.597h-0.516c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h384.774 c4.705,0,8.532,3.828,8.532,8.532v24.564h-8.531c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h24.047 c0.285,0,0.516,0.231,0.516,0.516v64.129H497C497,336.445,496.769,336.677,496.484,336.677z M408.822,167.822v40.597h-129.29 v-40.597c0-4.704,3.828-8.532,8.532-8.532H400.29C404.995,159.289,408.822,163.117,408.822,167.822z M232.467,167.822v40.597 h-129.29v-40.597c0-4.704,3.828-8.532,8.532-8.532h112.226C228.64,159.289,232.467,163.117,232.467,167.822z"
              />
            </svg>
            <Typography variant="body" className="summary uppercase">
              {t(summary)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h4" color="primary" className="py-4">
            {t('room.amenities')}
          </Typography>
          <Grid container columnSpacing={4}>
            {t(details, { returnObjects: true }).map((detail, index) => (
              <Grid item xs={12} md={6} key={`amenities-${index}`}>
                <Divider sx={{ my: 1 }} />
                <Typography key={index} variant="body1" className="amenities">
                  {detail}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Root>
  )
}

export default Amenities
