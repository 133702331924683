import * as React from 'react'

import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 600" width="900" height="600">
    <rect width="900" height="600" fill="#ED2939" />
    <rect width="600" height="600" fill="#fff" />
    <rect width="300" height="600" fill="#002395" />
  </svg>
)
