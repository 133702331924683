import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import Container from '@mui/material/Container'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'

import { styled } from '@mui/material/styles'

import Room from './components/Room'
import { Rooms as RoomsData } from './helper'

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

const Root = styled('div')(({ theme }) => ({
  '.parallax': {
    height: '40vh',
    backgroundPosition: '50% -500px',

    '@media only screen and (max-device-width: 900px)': {
      '&': {
        backgroundPosition: 'center',
      },
    },
  },
}))

const Rooms = ({ roomIndex }) => {
  const { t } = useTranslation()

  const [currentTab, setCurrentTab] = React.useState(parseInt(roomIndex, 10))

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const currentRoom = RoomsData[currentTab]

  return (
    <Root>
      <div className="parallax" style={{ backgroundImage: `url("${currentRoom.featuredImgBg}")` }}>
        <div className="shadow">
          <div className="banner">
            <Typography variant="overline" component="h1" className="title uppercase">
              {t('rooms.title')}
            </Typography>
            <Typography variant="h3" className="subtitle">
              {t(currentRoom.title)}
            </Typography>
          </div>
        </div>
      </div>

      <Container maxWidth="lg">
        <Tabs
          value={currentTab}
          variant="fullWidth"
          onChange={handleChange}
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          {RoomsData.map((room, index) => (
            <Tab key={index} label={t(room.title)} component={RouterLink} to={room.link} />
          ))}
        </Tabs>
        <Tabs
          value={currentTab}
          variant="scrollable"
          onChange={handleChange}
          scrollButtons="auto"
          sx={{ display: { xs: 'flex', md: 'none' } }}
        >
          {RoomsData.map((room, index) => (
            <Tab key={index} label={t(room.title)} component={RouterLink} to={room.link} />
          ))}
        </Tabs>
      </Container>
      {RoomsData.map((room, index) => (
        <TabPanel key={index} value={currentTab} index={index}>
          <Room room={room} />
        </TabPanel>
      ))}
    </Root>
  )
}

export default Rooms
