import * as React from 'react'

import Amenities from './Amenities'
import CardDescription from '../../../components/CardDescription'
import Container from '@mui/material/Container'
import Landscape from '../../../components/Landscape'
import Photos from './Photos'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

const Root = styled('div')(({ theme }) => ({}))

const Room = ({ room }) => {
  const { t } = useTranslation()

  const { title, description, images, amenities, price, priceDetail } = room

  return (
    <Root>
      <Container maxWidth="lg" sx={{ my: 10 }}>
        <CardDescription title={t(title)} description={t(description)} action={t(price)} priceDetail={priceDetail} />
      </Container>
      <Photos images={images} />
      <Amenities amenities={amenities} />
      <Container maxWidth="lg" sx={{ my: 10 }}>
        <CardDescription
          title={t('home_overview_title')}
          description={t('home_overview_description')}
          to="/overview"
          action={t('home_overview_button')}
        />
      </Container>
      <Landscape />
    </Root>
  )
}

export default Room
