import { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import HomeIcon from '@mui/icons-material/Home'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Link from '@mui/material/Link'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PhoneIcon from '@mui/icons-material/Phone'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import FlagEn from '../assets/flag_en'
import FlagFr from '../assets/flag_fr'

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  '.label': {
    marginLeft: theme.spacing(1),
    lineHeight: 1,
    fontWeight: 700,
  },
}))

const LanguageSwitcher = () => {
  const { i18n } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSwitchLocale = locale => {
    localStorage.setItem('locale', locale)
    i18n.changeLanguage(locale)
    handleClose()
  }

  const getLanguageIcon = () => (i18n.language === 'fr' ? <FlagFr /> : <FlagEn />)

  return (
    <>
      <Chip
        label={getLanguageIcon()}
        onClick={handleClick}
        sx={{ display: { xs: 'none', md: 'flex' }, marginRight: 1 }}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItemStyled className="flex items-center justify-between" onClick={() => handleSwitchLocale('fr')}>
          <FlagFr />
          <Typography className="label" variant="overline" display="block">
            Français
          </Typography>
        </MenuItemStyled>
        <MenuItemStyled className="flex items-center justify-between" onClick={() => handleSwitchLocale('en')}>
          <FlagEn />
          <Typography className="label" variant="overline" display="block">
            English
          </Typography>
        </MenuItemStyled>
      </Menu>
    </>
  )
}

const Root = styled('div')(({ theme }) => ({
  '.iconContainer': {
    position: 'relative',
    marginRight: theme.spacing(1),
    display: 'block',
  },
  '.homeLink:hover .clover': {
    transform: 'rotate(360deg)',
    opacity: 0,
  },

  '.home': {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    transition: 'opacity 2s ease-in-out',
  },

  '.homeLink:hover .home': {
    opacity: 1,
  },
  '.clover': {
    transition: '2s',
  },
}))

const Header = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <AppBar position="sticky" color="default">
      <Toolbar color="secondary">
        <Root className="flex grow">
          <Link underline="none" href="/" className="flex homeLink">
            {!isMobile && (
              <div className="iconContainer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="clover"
                  viewBox="0 0 24 24"
                  style={{ width: 24, height: 24, color: '#b28e59' }}
                  fill="currentColor"
                >
                  <path d="M12,11.18C15.3,8.18 17,6.64 17,4.69C17,3.19 15.75,2 14.25,2C13.39,2 12.57,2.36 12,3C11.43,2.36 10.61,2 9.69,2C8.19,2 7,3.25 7,4.75C7,6.64 8.7,8.18 12,11.18M11.18,12C8.18,8.7 6.64,7 4.69,7C3.19,7 2,8.25 2,9.75C2,10.61 2.36,11.43 3,12C2.36,12.57 2,13.39 2,14.31C2,15.81 3.25,17 4.75,17C6.64,17 8.18,15.3 11.18,12M12.83,12C15.82,15.3 17.36,17 19.31,17C20.81,17 22,15.75 22,14.25C22,13.39 21.64,12.57 21,12C21.64,11.43 22,10.61 22,9.69C22,8.19 20.75,7 19.25,7C17.36,7 15.82,8.7 12.83,12M12,12.82C8.7,15.82 7,17.36 7,19.31C7,20.81 8.25,22 9.75,22C10.61,22 11.43,21.64 12,21C12.57,21.64 13.39,22 14.31,22C15.81,22 17,20.75 17,19.25C17,17.36 15.3,15.82 12,12.82Z" />
                </svg>
                <HomeIcon className="home" />
              </div>
            )}
            <Typography component="h1" color="inherit" noWrap className="grow" sx={{ fontWeight: 500 }}>
              Manoir de l'As de Trèfle
            </Typography>
          </Link>
        </Root>
        <LanguageSwitcher />
        <Button variant="contained" startIcon={<PhoneIcon />} href="tel:+33677682959">
          {t('layout_header_book_now')}
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default Header
